import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Destaca pel seu color vermell o taronjós que en temps humit esdevé viscós. El petit capell de fins a 3 cm de diàmetre primer és convex i amb mamelló, essent després més pla, ondulat i a vegades un poc deprimit al centre. Les làmines són adnades o un poc decurrents, separades, normalment connectades les unes a les altres. El peu és cilíndric, un poc ondulat i a vegades més prim a la base, llis i viscós. Les espores són blanques en massa, el·lipsoides però més amples per un dels extrems, de 5,5-9,5 x 4,5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      